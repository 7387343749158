.payments-container {
  display: flex;
  justify-content: space-between;
  padding: 1% 0;
}

.payments-column {
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2%;
}

.toggle-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.payment_logo {
  width: 50%;
}

.payment-containerplan {
  height: 750px;
}

.moonclerkholder {
  height: auto;
  min-height: 1300px;
}

.btn-validate {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  min-height: 50px;
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  position: relative;
}

.btn-validate:hover {
  background-color: #de416c;
  box-shadow: 0px 15px 20px #c26f85;
  color: #fff;
  transform: translateY(-7px);
}

.btn-validate:active {
  transform: translateY(1px);
}

.lds-dual-ring1validate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  width: 50px;
  height: 28px;
  margin-bottom: 5px;
}

.lds-dual-ring1validate:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 6px solid #de416c;
  border-color: #de416c transparent #de416c transparent;
  animation: lds-dual-ring1 1.2s linear infinite;
}

.btn-validate:hover .lds-dual-ring1validate:after {
  border-color: #fff transparent #fff transparent;
}

@keyframes lds-dual-ring1validate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .moonclerkholder {
    height: auto;
    min-height: 1450px;
  }
}

@media (max-width: 900px) {
  .payments-container {
    flex-direction: column;
    align-items: center;
  }
  .payments-column {
    width: 100%;
    margin: 10px 0;
  }
  .moonclerkholder {
    height: auto;
    min-height: 1480px;
  }
}

@media (max-width: 768px) {
  .payments-container {
    flex-direction: column;
    align-items: center;
  }
  .payments-column {
    width: 100%;
    margin: 10px 0;
  }
  .moonclerkholder {
    height: auto;
    min-height: 1480px;
  }
}

@media (max-width: 700px) {
  .payments-container {
    flex-direction: column;
    align-items: center;
  }
  .payments-column {
    width: 100%;
    margin: 10px 0;
  }
  .moonclerkholder {
    min-height: 1450px;
  }
}
