.footer-bottom {
  width: 100%;
  text-align: center;
  color: #27b3df;
}

.policy-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1%;
  width: 30%;
}

.footer-bottom hr {
  margin-top: 0;
  border: none;
  border-top: 1px solid currentColor;
}

.policy a,
.policy a:hover,
.policy a:focus {
  color: #27b3df;
  text-decoration: none;
}

.policy a:hover {
  text-decoration: underline;
}

.footer-text {
  text-align: center;
  color: #fff;
  font-size: 1.1em;
  max-width: 58%;
  margin-top: 30px;
}

.footer-text p {
  line-height: 1.6;
  color: #ffffff;
}

.footer-text strong {
  font-weight: bold;
  color: #ffffff;
}

/* For screens larger than 1250px */
@media (max-width: 1250px) {
  .footer-text {
    font-size: 1em;
    max-width: 70%;
  }
  .policy {
    width: 50%;
  }
}

/* For screens smaller than 1024px */
@media (max-width: 1024px) {
  .policy {
    width: 60%;
  }
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
  .footer-bottom {
    font-size: 0.9em;
  }
  .footer-text {
    font-size: 1em;
  }
}

/* For screens smaller than 576px */
@media (max-width: 576px) {
  .policy {
    width: 80%;
  }
  .footer-bottom {
    font-size: 0.8em;
  }
  .footer-text {
    font-size: 0.9em;
  }
}

/* For screens smaller than 375px */
@media (max-width: 375px) {
  .policy {
    width: 100%;
  }
  .footer-bottom {
    font-size: 0.8em;
  }
  .footer-text {
    font-size: 0.8em;
  }
}

/* For screens smaller than 320px */
@media (max-width: 320px) {
  .footer-bottom {
    font-size: 0.7em;
  }
  .footer-text {
    font-size: 0.75em;
  }
}

/* For screens smaller than 280px */
@media (max-width: 280px) {
  .footer-bottom {
    font-size: 0.65em;
  }
  .footer-text {
    font-size: 0.7em;
  }
}
