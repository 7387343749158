::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  background-color: #000434;
  color: aliceblue;
  font-size: large;
  font-family: "PublicaSansRound-Lt", sans-serif;
}

/* Show dropdown menu on hover */
.hover-dropdown:hover .dropdown-menu {
  display: block;
}

/* Optional: Hide dropdown menu on hover out */
.hover-dropdown .dropdown-menu:hover {
  display: block;
}

/* Change color of <p> on hover */

/* Remove white background color from dropdown */
.hover-dropdown .dropdown-menu {
  background-color: transparent;
  padding: 0; /* Remove padding to make the dropdown more compressed */
}

/* Set color for dropdown items and further adjust padding */
.dropdown-item {
  color: rgb(255, 255, 255);
  padding: 2px 10px; /* Further compress the dropdown */
}

/* Remove hover background color for dropdown items */
.dropdown-item:hover {
  background-color: transparent;
}

/* Set color for <hr> */
.custom-hr {
  border-color: #de416c;
}

.demoVideo {
  position: "absolute";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
  display: "flex";
  flex-direction: "column";
  justify-content: "start";
  align-items: "center";
  color: "white";
  z-index: 1;
}

.custom-button {
  background-color: #e03f6d;
  color: white;
  text-transform: none;
  padding-top: calc(0.375rem + 8px);
  padding-bottom: calc(0.375rem + 8px);
}

.banner-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.liveDemo {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.content-overlay-live {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.price_text {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.Pricing {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.pricing_choose {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
  margin-bottom: -20px;
}

.price_table {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.price_market {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.get_agent {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.TailoredOffer_BG {
  position: relative;
}

.TailoredOffer_BG::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(6, 11, 48, 0.7), rgba(6, 11, 48, 0.7)),
    url("./img/bgdemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1); /* This will flip the background horizontally */
  z-index: -1; /* This ensures the background is behind the content */
}

.CheckImg {
  width: 30px; /* Adjust this value to your desired size */
  height: auto; /* Maintain the aspect ratio */
  margin-right: 10px; /* Adjust spacing between the image and the text */
  margin-left: 20px;
}

.ratings {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.footer {
  position: relative;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(6, 11, 48, 0.7), rgba(6, 11, 48, 0.7)),
    url("./img/bgdemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1); /* This will flip the background horizontally */
  z-index: -1; /* This ensures the background is behind the content */
}

.banner-button,
.banner-button:after {
  width: 250px;
  height: 60px;
  font-size: 20px;
  font-family: "PublicaSansRound-Lt", sans-serif;
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  margin-top: 4rem;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 12px;

  background-color: #e03f6d;
  text-transform: none;
}

.banner-button:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);

  content: "Super Smart Agent";
  display: block;
  position: absolute;
  top: 10;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent 3%,
    #00e6f6 3%,
    #00e6f6 5%,
    #ff0022 5%
  );
  text-shadow: -3px -3px 0px #f8f005, 3px 3px 0px #00e6f6;
  clip-path: var(--slice-0);
}

.banner-button:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (max-width: 1024px) {
  .banner-button,
  .banner-button:after {
    width: 250px;
    height: 60px;
    font-size: 14px;
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  .banner-button,
  .banner-button:after {
    width: 250px;
    height: 60px;
    font-size: 16px;
    border-radius: 8px;
  }
}

.router-link {
  text-decoration: none;
  color: white;
}

.Onboarding {
  position: relative;
}

.Onboarding::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(6, 11, 48, 0.7), rgba(6, 11, 48, 0.7)),
    url("./img/bgdemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1); /* This will flip the background horizontally */
  z-index: -1; /* This ensures the background is behind the content */
}

.login {
  background-image: url("./img/loginbg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.signup {
  background-image: url("./img/loginbg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}

.appointment {
  position: relative;
}

.appointment::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(6, 11, 48, 0.7), rgba(6, 11, 48, 0.7)),
    url("./img/bgdemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1); /* This will flip the background horizontally */
  z-index: -1; /* This ensures the background is behind the content */
}

.payment-bg {
  background-image: url("./img/bglivedemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; /* You can set a specific height if needed */
  width: 100%;
  display: block;
}
