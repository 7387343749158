/* GetAgent.css */
.get_agent {
}

.get_agent .text {
  margin-top: 100px;
  font-size: 1.8rem;
}

.get_agent .price {
  font-weight: 600;
}

.get_agent .special {
  color: #27b3df;
  font-weight: 500;
}

.get_agent .logo {
  width: 60%;
}

.get_agent .title {
  margin-top: 1rem;
  font-size: 2.8rem;
  font-weight: 700;
  text-align: center;
}

.get_agent .banner-button {
  font-weight: bold;
}

.logoget {
  width: 25%;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .get_agent .text {
    font-size: 1.7rem;
  }

  .get_agent .title {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .get_agent .text {
    font-size: 1.5rem;
  }

  .get_agent .title {
    font-size: 2rem;
  }
}

@media (max-width: 425px) {
  .get_agent .text {
    font-size: 1.3rem;
  }

  .get_agent .title {
    font-size: 1.3rem;
  }
}

@media (max-width: 280px) {
  .get_agent .text {
    font-size: 1rem;
    margin-top: 50px;
  }

  .get_agent .title {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .get_agent .price {
    font-size: 0.8rem;
  }

  .get_agent .special {
    font-size: 0.9rem;
  }

  .get_agent .logo {
    width: 40%;
  }

  .logoget {
    width: 20%;
  }

  .get_agent .banner-button {
    font-size: 0.8rem;
  }
}
