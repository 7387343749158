.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #2c3553;
  padding: 50px;
  border-radius: 20px;
  width: 750px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button-form {
  background: none;
  border: none;
  font-size: 35px;
  cursor: pointer;
  color: #ffffff;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.close-button-form:hover {
  color: #ff0077;
  transform: scale(1.2);
}

.close-button-form:focus {
  outline: none;
  color: #ff0077;
}

.form-submitted {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.form-group label {
  font-weight: 600;
  color: #e0e0e0;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 15px;
  border: none;
  border-left: 5px solid #ff0077;
  background-color: #f4f4f4;
  color: #2c3553;
  font-size: 1.1em;
  border-radius: 8px;
}

.form-group textarea {
  resize: vertical;
  min-height: 30px;
  max-height: 60px;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  background-color: #e8e8e8;
}

.info-icon {
  color: #00aeef;
  margin-left: 5px;
  cursor: pointer;
}

.section-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #e0e0e0;

  width: 100%;
  text-align: left;
}

.save-button {
  background-color: #ff0077;
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  border-radius: 20px;
  font-size: 1.2em;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  display: block;
  margin-top: 20px;
}

.save-button:focus {
  outline: none;
  background-color: #e6006b;
}

.button-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.agent-details {
  border: 2px solid #ff0077;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 25px;
  background-color: #2c3553;
  color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.agent-details:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  background-color: #3b4567;
}

.agent-details .form-group {
  margin-bottom: 15px;
}

.agent-details .form-group label {
  color: #ff0077;
  font-weight: 600;
  margin-bottom: 5px;
}

.agent-details .remove-agent-button {
  background-color: #ff0077;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
  display: block;
  text-align: center; /* Center the text */
}

.agent-details .remove-agent-button:hover {
  background-color: #e6006b;
  transform: scale(1.05); /* Slightly increase the size on hover */
}

.agent-details .remove-agent-button:focus {
  outline: none;
  background-color: #e6006b;
}

.agent-details .form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.agent-details .button-row {
  display: flex;
  justify-content: center;
}

.add-agent-button {
  background: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s ease;
  margin-top: 10px;
}

.add-agent-button:hover {
  background: #0056b3;
}
