.savings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px;
  width: 100%;
  box-sizing: border-box;
}

.intro-text p:first-child {
  font-weight: 600;
  font-size: 30px;
  color: #27b3df;
  text-align: center;
}

.intro-text p:last-child {
  font-weight: 600;
  font-size: 60px;
  text-align: center;
}

.savings-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 1000px;
  margin-top: 100px;
}

.header-row,
.data-row {
  font-weight: 700;
  text-align: center;
}

.header-row {
  font-size: 32px;
  color: #a04167;
}

.header-row td,
.data-row td {
  border-bottom: 1px solid #27b3df;
  padding: 25px;
}

.logo-cell p {
  font-weight: bold;
  font-size: 24px;
}

.logo-cell img {
  width: 100%;
  max-width: 230px;
}

.savings-amount span {
  font-weight: bold;
  font-size: 45px;
}

.border-right {
  border-right: 1px solid #27b3df;
}

.border-left {
  border-left: 1px solid #27b3df;
}

.wrappers {
  --gold: #ffb338;
  --light-shadow: #77571d;
  --dark-shadow: #3e2904;
  display: grid;
  grid-template-areas: "overlap";
  place-content: center;
  text-transform: uppercase;
}

.wrappers > div {
  background-clip: text;
  -webkit-background-clip: text;
  color: #363833;
  font-weight: 900;
  font-size: 60px;
  grid-area: overlap;
  letter-spacing: 1px;
  -webkit-text-stroke: 4px transparent;
}

div.bg {
  background-image: repeating-linear-gradient(
    105deg,
    var(--gold) 0%,
    var(--dark-shadow) 5%,
    var(--gold) 12%
  );
  color: transparent;
  filter: drop-shadow(5px 15px 15px black);
  transform: scaleY(1.05);
  transform-origin: top;
}

div.fg {
  background-image: repeating-linear-gradient(
    5deg,
    var(--gold) 0%,
    var(--light-shadow) 23%,
    var(--gold) 31%
  );
  color: #1e2127;
  transform: scale(1);
}

.firetext {
  color: #000;
  font-family: "Caesar Dressing", cursive;
  font-size: 60px;
  text-transform: lowercase;
  vertical-align: middle;
  letter-spacing: 0.2em;
}

.fire {
  animation: animation 1s ease-in-out infinite alternate;
  -moz-animation: animation 1s ease-in-out infinite alternate;
  -webkit-animation: animation 1s ease-in-out infinite alternate;
  -o-animation: animation 1s ease-in-out infinite alternate;
}

.burn {
  animation: animation 0.65s ease-in-out infinite alternate;
  -moz-animation: animation 0.65s ease-in-out infinite alternate;
  -webkit-animation: animation 0.65s ease-in-out infinite alternate;
  -o-animation: animation 0.65s ease-in-out infinite alternate;
}

@keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

@-moz-keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

@-webkit-keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

@-o-keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

@media (max-width: 1024px) {
  .intro-text p:first-child {
    font-size: 28px;
  }

  .intro-text p:last-child {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .intro-text p:first-child {
    font-size: 24px;
  }

  .intro-text p:last-child {
    font-size: 36px;
  }

  .header-row,
  .data-row {
    font-size: 20px;
  }

  .header-row td,
  .data-row td {
    padding: 15px;
  }

  .logo-cell img {
    max-width: 180px;
  }

  .savings-amount span {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .intro-text p:first-child {
    font-size: 20px;
  }

  .intro-text p:last-child {
    font-size: 26px;
  }

  .header-row,
  .data-row {
    font-size: 16px;
  }

  .header-row td,
  .data-row td {
    padding: 10px;
  }

  .savings-amount span {
    font-size: 26px;
  }

  .logo-cell p {
    font-size: 14px;
  }

  .logo-cell img {
    max-width: 160px;
  }
}

@media (max-width: 375px) {
  .savings {
    margin-top: 80px;
  }

  .intro-text p:first-child {
    font-size: 16px;
  }

  .intro-text p:last-child {
    font-size: 20px;
  }

  .header-row,
  .data-row {
    font-size: 12px;
  }

  .header-row td,
  .data-row td {
    padding: 10px;
  }

  .logo-cell p {
    font-size: 12px;
  }

  .logo-cell img {
    max-width: 150px;
  }

  .savings-amount span {
    font-size: 24px;
  }

  .savings-table {
    margin-top: 50px;
  }
}

@media (max-width: 820px) {
  .intro-text p:first-child {
    font-size: 24px;
  }

  .intro-text p:last-child {
    font-size: 30px;
  }
}

@media (max-width: 320px) {
  .savings {
    margin-top: 20px;
  }

  .intro-text p:first-child {
    font-size: 15px;
  }

  .intro-text p:last-child {
    font-size: 15px;
  }

  .header-row,
  .data-row {
    font-size: 5px;
  }

  .header-row td,
  .data-row td {
    padding: 0.5px;
    font-size: 15px;
  }

  .logo-cell p {
    font-size: 15px;
  }

  .logo-cell img {
    max-width: 120px;
  }

  .savings-amount span {
    font-size: 20px;
  }

  .savings-table {
    margin-top: 5px;
  }
}
