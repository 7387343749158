.watchDemo {
  background: linear-gradient(rgba(6, 11, 48, 0.7), rgba(6, 11, 48, 0.7)),
    url("../../../../img/bgdemo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 125vh;
  display: block;
}

.textDemo1 {
  text-align: center;
  margin-bottom: 50px;
}

.textDemo1 img {
  height: 30%;
  margin-top: 20px; /* Replaces mt-5 */
}

.textDemo1 h1 {
  font-weight: 700;
  font-size: 48px;
  margin-top: 20px; /* Replaces mt-4 */
}

.textDemo1 p {
  font-size: 20px;
}

.vidDemos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_demo {
  width: 70%;
  max-height: 800px;
}

@media (max-width: 1024px) {
  .watchDemo {
    height: 110vh;
  }

  .textDemo1 h1 {
    font-size: 40px;
  }

  .textDemo1 p {
    font-size: 18px;
  }

  .video_demo {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .watchDemo {
    height: 94vh;
  }

  .textDemo1 h1 {
    font-size: 36px;
  }

  .textDemo1 p {
    font-size: 18px;
  }

  .video_demo {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .watchDemo {
    height: 70vh;
  }

  .textDemo1 h1 {
    font-size: 30px;
  }

  .textDemo1 p {
    font-size: 14px;
  }

  .video_demo {
    width: 90%;
  }
}

@media (max-width: 425px) {
  .watchDemo {
    height: 65vh;
  }

  .textDemo1 h1 {
    font-size: 24px;
  }

  .textDemo1 p {
    font-size: 12px;
  }

  .video_demo {
    width: 90%;
  }
}

@media (max-width: 280px) {
  .watchDemo {
    height: 60vh;
  }

  .textDemo1 h1 {
    font-size: 20px;
  }

  .textDemo1 p {
    font-size: 10px;
  }

  .video_demo {
    width: 95%;
  }
}
