.price_market {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content_wrapper {
  width: 75%;
  margin-top: 100px;
}

.main_title h1 {
  font-weight: 600;
  font-size: 54px;
}

.sub_content {
  width: 60%;
  margin-top: 20px;
}

.sub_title h1 {
  font-weight: 600;
  font-size: 44px;
}

.features {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.feature_column {
}

.feature_item {
  display: flex;
  align-items: center;
}

.feature_item img {
  margin-right: 10px;
}

.feature_item p {
  font-weight: 500;
  font-size: 26px;
}

.final_text h3 {
  font-weight: 600;
  font-size: 28px;
}

/* Responsive Styles for 1440px and below */
@media (max-width: 1440px) {
  .content_wrapper,
  .sub_content {
    width: 90%;
  }

  .main_title h1,
  .sub_title h1 {
    font-size: 40px;
  }

  .feature_item p {
    font-size: 30px;
  }

  .final_text h3 {
    font-size: 20px;
  }
}

/* Responsive Styles for 1200px and below */
@media (max-width: 1200px) {
  .main_title h1 {
    font-size: 40px;
  }
}

/* Responsive Styles for 1050px and below */
@media (max-width: 1050px) {
  .main_title h1,
  .sub_title h1 {
    font-size: 30px;
  }

  .feature_item p {
    font-size: 24px;
  }

  .final_text h3 {
    font-size: 18px;
  }
}

/* Responsive Styles for 992px and below */
@media (max-width: 992px) {
  .main_title h1 {
    font-size: 36px;
  }
}

/* Responsive Styles for 820px and below */
@media (max-width: 820px) {
  .main_title h1 {
    font-size: 30px;
  }

  .sub_title h1 {
    font-size: 30px;
  }

  .feature_item p {
    font-size: 16px;
  }

  .final_text h3 {
    font-size: 16px;
  }
}

/* Responsive Styles for 768px and below */
@media (max-width: 768px) {
  .content_wrapper,
  .sub_content {
    width: 90%;
  }

  .main_title h1,
  .sub_title h1 {
    font-size: 30px;
  }

  .feature_item p {
    font-size: 18px;
  }

  .final_text h3 {
    font-size: 18px;
  }
}

/* Responsive Styles for 425px and below */
@media (max-width: 435px) {
  .content_wrapper,
  .sub_content {
    width: 95%;
  }

  .CheckImg {
    height: 25px;
  }

  .main_title h1 {
    font-size: 24px;
  }

  .sub_title h1 {
    font-size: 20px;
  }

  .feature_item p {
    font-size: 12px;
  }

  .final_text h3 {
    font-size: 16px;
  }
}
