.my-onboarding-title {
  font-size: 2em;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
  text-align: center;
  letter-spacing: 2px;
}

.my-onboarding-subtitle {
  font-size: 2em;
  color: #555;
  margin-bottom: 25px;
  text-align: center;
  font-style: italic;
  letter-spacing: 1px;
}

.my-onboarding-subtitle {
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: normal;
  color: #b9b9b9;
}

.my-onboarding-form {
  display: flex;
  flex-direction: column;
  width: 800px;
  background: #3b4567;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.my-input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.my-input-item {
  display: flex;
  flex-direction: column;
}

.my-onboarding-label {
  margin-bottom: 0.8em;
  font-weight: bold;
  color: #ffffff;
}

.my-onboarding-input {
  width: 100%;
  padding: 0.75em;
  margin-bottom: 1.5em;
  border: none;
  border-left: 4px solid #ff0077;
  background-color: white;
  color: #2c3553;
  font-size: 0.9em;
  border-radius: 5px;
}

.my-onboarding-input::placeholder {
  color: #bbb;
}

.my-onboarding-input:focus {
  outline: none;
  background-color: #f0f0f0;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.my-onboarding-button {
  background-color: #00aeef;
  color: #ffffff;
  border: none;
  width: 120px;
  padding: 0.6em;
  border-radius: 15px;
  font-size: 0.9em;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.my-onboarding-button:hover {
  background-color: #ff0077;
  transform: translateY(-2px);
}

.my-onboarding-button:active {
  transform: translateY(1px);
}

/* agent detail */
.agent-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.agent-input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tooltip-icon {
  margin-left: 8px;
  color: #bbb;
  cursor: pointer;
  position: relative;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .tooltip {
  visibility: hidden;
  width: 500px;
  max-height: 280px;
  overflow-y: auto;
  background-color: #2c3553;
  color: white;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* additonal detail */
.my-onboarding-textarea {
  resize: vertical;
  min-height: 40px;
  max-height: 60px;
  outline: none;
}

.bot-channels {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  color: #2c3553;
  margin-bottom: 20px;
}

.bot-channel {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.bot-channel:hover {
  background-color: #f0f0f0;
}

.bot-channel input[type="checkbox"] {
  margin-right: 5px;
}

.file-upload {
  display: block;
  margin-top: 10px;
  cursor: pointer;
  color: #00aeef;
  transition: color 0.3s ease;
}

.file-upload:hover {
  color: #ff0077;
}

.file-delete {
  cursor: pointer;
  margin-left: 10px;
  color: red;
  transition: color 0.3s ease;
}

.file-delete:hover {
  color: darkred;
}

.onboarding-add-Agent {
  display: block;
  margin: 5px auto;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff5722;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.onboarding-add-Agent:hover {
  background-color: #e64a19;
}

.onboarding-add-Agent:active {
  transform: translateY(2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.onboarding-add-Agent:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(255, 87, 34, 0.3);
}

.channel-info {
  color: #ffd000;
  font-size: 14px;
  margin-top: -20px;
  margin-bottom: 20px;
}
