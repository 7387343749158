.ratings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card_rating_holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 5rem;
  width: 70%;
}

.rating_card {
  background-color: #4c4d62;
  padding: 40px;
  width: calc(33.333% - 20px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card_footer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
}

.profile-image img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.footer-content {
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.company {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.stars img {
  height: 1.2em;
  width: 1.2em;
  margin-right: 3px;
}

.headerRating {
  margin-top: 100px;
}

.headerRating p {
  font-weight: 700;
  font-size: 45px;
  text-align: center;
}

@media (max-width: 1440px) {
  .card_rating_holder {
    width: 75%;
  }
  .rating_card {
    padding: 20px;
  }
}

@media (max-width: 1883px) {
  .card_rating_holder {
    width: 85%;
  }
  .rating_card {
    padding: 20px;
  }
}

@media (max-width: 1069px) {
  .card_rating_holder {
    width: 95%;
  }
  .rating_card {
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .card_rating_holder {
    width: 95%;
  }
  .rating_card {
    padding: 20px;
  }
}

@media (max-width: 885px) {
  .profile-image img {
    width: 60px;
    height: 60px;
  }

  .footer-content {
    margin-left: 5%;
  }

  .company {
    font-size: 0.9em;
  }

  .stars img {
    height: 1em;
    width: 1em;
  }

  .card_footer {
    padding-right: 10px;
  }
}

@media (max-width: 768px) {
  .rating_card {
    width: 50%;
    padding: 30px;
  }
  .stars img {
    height: 1.5em;
    width: 1.5em;
  }
  .headerRating p {
    font-size: 40px;
  }
}

@media (max-width: 679px) {
  .rating_card {
    width: 60%;
    padding: 30px;
  }
  .stars img {
    height: 1.5em;
    width: 1.5em;
  }
  .headerRating p {
    font-size: 40px;
  }
}

@media (max-width: 567px) {
  .rating_card {
    width: 70%;
    padding: 30px;
  }
  .stars img {
    height: 1.5em;
    width: 1.5em;
  }
  .headerRating p {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  .rating_card {
    width: 70%;
    padding: 30px;
  }
  .stars img {
    height: 1.5em;
    width: 1.5em;
  }
  .headerRating p {
    font-size: 40px;
  }
  .headerRating p {
    text-align: center;
  }
}

@media (max-width: 487px) {
  .card_rating_holder {
    width: 95%;
    gap: 10px;
  }
  .rating_card {
    width: 80%;
    padding: 20px;
    margin-bottom: 10px;
  }
  .stars img {
    height: 1.3em;
    width: 1.3em;
  }
  .headerRating p {
    text-align: center;
    font-size: 28px;
  }
}

@media (max-width: 487px) {
  .card_rating_holder {
    width: 95%;
    gap: 10px;
  }
  .rating_card {
    width: 90%;
    padding: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 280px) {
  .rating_card {
    width: 90%; /* Make the rating card take up the full width */
    padding: 15px; /* Reduce padding */
  }

  .profile-image img {
    width: 65px; /* Decrease profile image size */
    height: 65px;
  }

  .footer-content {
    margin-left: 3%; /* Reduce the margin for footer content */
  }

  .company {
    font-size: 1.1em; /* Decrease the company text size */
  }

  .stars img {
    height: 1em; /* Decrease the size of the stars */
    width: 1em;
    margin-right: 2px; /* Adjust the margin between stars */
  }

  .headerRating p {
    font-size: 18px; /* Decrease the font size of the header rating */
    text-align: center; /* Ensure the text remains centered */
  }

  .card_footer {
    padding-right: 5px; /* Reduce padding on the right of card footer */
  }

  .card_rating_holder {
    width: 100%; /* Ensure the container takes up full width */
    gap: 5px; /* Reduce the gap between cards */
  }
}
