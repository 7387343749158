.onboarding-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 80vh;
  padding: 20px;
  background-color: #001f3f;
  color: white;
}

.my-onboarding-form {
  display: flex;
  flex-direction: column;
  width: 800px;
  background: #3b4567;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.title_onboard {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
  animation: progressiveTitle 2s ease-in-out;
}

@keyframes progressiveTitle {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
