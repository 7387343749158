.banner-bg {
  background-image: url("../../../img/banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-title,
.banner-subtitle {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
}

.banner-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 50px;
}

.banner-subtitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 40px;
}

.glowing-text {
  font-weight: 500;
  color: transparent;
  -webkit-text-stroke-width: 3px;
  text-stroke-width: 3px;
  animation: strokeAnimation 6s infinite alternate;
}

.text-overlays {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.text-centerss {
  margin-right: 15rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@keyframes strokeAnimation {
  0%,
  20% {
    -webkit-text-stroke-color: #e03f6d;
    text-stroke-color: #e03f6d;
  }
  50% {
    -webkit-text-stroke-color: #27b3df;
    text-stroke-color: #27b3df;
  }
  80%,
  100% {
    -webkit-text-stroke-color: #e03f6d;
    text-stroke-color: #e03f6d;
  }
}

@media (max-width: 1440px) {
  .text-centerss {
    margin-right: 5rem;
    width: 50%;
  }
}

@media (max-width: 1240px) {
  .text-centerss {
    margin-right: 3rem;
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .banner-title {
    font-size: 40px;
  }
  .banner-subtitle {
    font-size: 30px;
  }
  .text-centerss {
    margin-right: 3rem;
    width: 60%;
  }
}

@media (max-width: 964px) {
  .banner-title {
    font-size: 35px;
    text-align: center;
  }
  .banner-subtitle {
    font-size: 28px;
    text-align: center;
  }
  .glowing-text {
    font-size: 32px;
    -webkit-text-stroke-width: 2px;
    text-stroke-width: 2px;
  }
}

@media (max-width: 849px) {
  .banner-title {
    font-size: 30px;
    text-align: center;
  }
  .banner-subtitle {
    font-size: 23px;
    text-align: center;
  }
  .glowing-text {
    font-size: 32px;
    -webkit-text-stroke-width: 2px;
    text-stroke-width: 2px;
  }
}

@media (max-width: 768px) {
  .banner-bg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-title {
    font-size: 42px;
    text-align: center;
  }
  .banner-subtitle {
    font-size: 35px;
    text-align: center;
  }
  .text-overlays {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-centerss {
    margin-right: 0rem;
    width: 100%;
  }
}

/* Continue to smaller screens */

@media (max-width: 637px) {
  .banner-title {
    font-size: 28px;
    text-align: center;
  }
  .banner-subtitle {
    font-size: 22px;
    text-align: center;
  }
  .text-centerss {
    width: 100%;
    margin-right: 0;
  }
  .glowing-text {
    font-size: 24px;
    -webkit-text-stroke-width: 1.5px;
    text-stroke-width: 1.5px;
  }
}

@media (max-width: 280px) {
  .banner-title {
    font-size: 24px;
    text-align: center;
  }
  .banner-subtitle {
    font-size: 18px;
    text-align: center;
  }
  .banner-button {
    font-size: 14px;
    padding: 10px 20px;
  }
  .banner-bg {
    height: 50vh;
  }
  .text-overlays {
    padding: 0 2%;
    justify-content: center;
    align-items: center;
  }
  .glowing-text {
    -webkit-text-stroke-width: 1px;
    text-stroke-width: 1px;
  }
}
