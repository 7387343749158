.textDemo {
  margin-top: 50px;
  text-align: center;
}

.textDemo img {
  height: 30%;
}

.liveDemoHeader {
  font-weight: 700;
}

.vidDemo {
  margin-top: 5rem;
  width: 100%;
  position: relative;
}

.liveDemoImage {
  width: 60%;
  height: auto;
  display: block;
  margin: auto;
}

.livechat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 79%;
  width: 49%;
  background-color: rgb(255, 255, 255);
  padding: 2px;
}

.headerchat {
  background-color: #000434;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.close-buttonchat {
  background: none;
  border: none;
  font-size: 24px;
  color: aliceblue;
  cursor: pointer;
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}

ul,
li {
  list-style-type: none;
}

.wrapper {
  height: 69%;
}

.wrapper ul {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 30px;
}

.wrapper ul li {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.65);
  position: relative;
  border-radius: 10px;
  font: 1em/1.6 "arial";
  clear: both;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.35);
  box-shadow: 0px 4px 2px -2px gray;
}

.wrapper ul li:nth-child(odd) {
  float: left;
  margin-left: 80px;
}

.wrapper ul li:nth-child(even) {
  float: right;
  margin-right: 80px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
  background-color: rgb(39, 179, 223);
}

.wrapper ul li:before,
.wrapper ul li:after {
  position: absolute;
  content: "";
}

.wrapper ul li:before {
  width: 45px;
  height: 45px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper ul li.sent {
  float: right;
  margin-right: 80px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
  background-color: rgb(39, 179, 223);
}

.wrapper ul li.received {
  float: left;
  margin-left: 80px;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.75);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.35);
}

.wrapper ul li.sent:before {
  right: -80px;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png");
}

.wrapper ul li.received:before {
  left: -80px;
  background-image: url("../../../../img/logo_small.png");
}

.footerchat {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
}

.buttonchat {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  background: #2f3640;
  border-radius: 15px;
}

.searchButton {
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(
    --gradient-2,
    linear-gradient(90deg, #2af598 0%, #009efd 100%)
  );
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  right: 10px;
}

.buttonchat:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
  transform: translateY(-3px);
}

.buttonchat:active {
  box-shadow: none;
  transform: translateY(0);
}

.sendimg {
  width: 80%;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  color: white;
  font-size: 15px;
  padding: 24px 50px 24px 26px;
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.message.received p {
  margin: 0;
  padding: 0.5em 0;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinkCursor {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.typewriter {
  max-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  animation: typewriter 2s steps(25, end), blinkCursor 0.5s step-end infinite;
}

.typing-indicator {
  display: inline-block;
  color: #888;
  font-style: italic;
}

.typing-indicator::after {
  content: " .";
  animation: typingDots 1s steps(10, end) infinite;
}

@keyframes typingDots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@media (max-width: 1024px) {
  .liveDemoImage {
    width: 75%;
    height: auto;
  }
  .livechat {
    width: 61%;
  }
  .wrapper ul {
    padding-right: 20px;
  }
  .wrapper ul li {
    margin-bottom: 15px;
    padding: 8px 15px;
    font-size: 0.9em;
    border-radius: 8px;
    box-shadow: 0px 3px 2px -2px gray;
  }

  .wrapper ul li:nth-child(odd),
  .wrapper ul li:nth-child(even) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .wrapper ul li:before {
    width: 35px;
    height: 35px;
  }

  .wrapper ul li:nth-child(odd):before {
    left: -65px;
  }

  .wrapper ul li:nth-child(even):before {
    right: -65px;
  }

  .searchBox {
    width: 100%;
  }

  .searchButton {
    width: 40px;
    height: 40px;
  }

  .buttonchat {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchInput {
    padding: 18px 35px 18px 18px;
    font-size: 14px;
  }

  .sendimg {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .liveDemoImage {
    width: 90%;
    height: auto;
  }
  .livechat {
    width: 74%;
  }
}

@media (max-width: 668px) {
  .wrapper ul {
    padding-right: 15px;
  }
  .wrapper ul li {
    margin-bottom: 10px;
    padding: 6px 12px;
    font-size: 0.8em;
    border-radius: 6px;
    box-shadow: 0px 2px 1px -2px gray;
  }

  .wrapper ul li:nth-child(odd),
  .wrapper ul li:nth-child(even) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .wrapper ul li:before {
    width: 30px;
    height: 30px;
  }

  .wrapper ul li:nth-child(odd):before {
    left: -55px;
  }

  .wrapper ul li:nth-child(even):before {
    right: -55px;
  }

  .searchBox {
    width: 100%;
  }

  .searchButton {
    width: 35px;
    height: 35px;
  }

  .searchInput {
    padding: 15px 30px 15px 15px;
    font-size: 13px;
  }

  .sendimg {
    width: 80%;
  }
}

@media (max-width: 550px) {
  .liveDemoImage {
    width: 90%;
    height: auto;
  }

  .livechat {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .close-buttonchat {
    display: block;
  }

  .wrapper {
    background-color: rgb(255, 255, 255);
    height: 86%;
  }

  .show-chat-button {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: #645bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .wrapper ul {
    padding-right: 10px;
  }

  .wrapper ul li {
    margin-bottom: 10px;
    padding: 6px 10px;
    font-size: 0.8em;
    border-radius: 6px;
    box-shadow: 0px 2px 1px -1px gray;
  }

  .wrapper ul li:nth-child(odd),
  .wrapper ul li:nth-child(even) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .wrapper ul li:before {
    width: 25px;
    height: 25px;
  }

  .wrapper ul li:nth-child(odd):before {
    left: -50px;
  }

  .wrapper ul li:nth-child(even):before {
    right: -50px;
  }
}

@media (max-width: 280px) {
  .textDemo h1,
  .textDemo p,
  .liveDemoHeader,
  .wrapper ul li {
    font-size: 12px; /* Decrease font size for general text */
  }

  .wrapper ul li p {
    font-size: 10px; /* Decrease font size for message text */
  }

  .textDemo img {
    height: 20%; /* Reduce image height */
  }

  .liveDemoHeader {
    font-size: 14px;
  }

  .textDemo1 h1 {
    font-size: 18px;
  }

  .textDemo1 p {
    font-size: 10px;
  }

  .searchInput {
    font-size: 10px;
  }

  .searchButton {
    font-size: 10px;
  }

  .buttonchat {
    font-size: 10px;
  }
}
