/* General Styles */
.pricing_choose {
  padding: 20px;
}

.header-section {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 50px;
}

.pricing-title {
  font-weight: 700;
}

.intro-section {
  margin-bottom: 30px;
}

.intro-text {
  font-weight: 600;
}

.highlight-text {
  color: #27b3df;
}

.intro-paragraph {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.subheading {
  font-weight: 700;
  margin-top: 20px;
}

.benefit-section {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.CardInfo {
  background-color: #4c4d62;
  padding: 13px;
  width: 55%;
  margin: 0 auto 20px;
  display: flex;
  margin-bottom: 30px;
}

.CardInfo-tail {
  background-color: #4c4d62;
  padding: 13px;
  width: 54%;
  margin: 0 auto 20px;
  display: flex;
  margin-bottom: 30px;
}

.CheckImg {
  margin-right: 20px;
}

.text-start h3,
.text-start h5 {
  color: white;
}

.whytitle {
  font-weight: 700;
}

.Offer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.offer-box {
  border: 2px solid #a04167;
  position: relative;
  padding: 40px 50px;
  text-align: center;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer-title {
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 20px;
  color: #27b3df;
  font-weight: bold;
  background: linear-gradient(rgba(6, 11, 48, 0.7), rgba(6, 11, 48, 0.7)),
    url("../../../../img/bgdemo.png");
  background-size: 190%;
  background-position: 40% 60%;
  background-repeat: no-repeat;
  border-radius: 18px;
  /* Removed any border */
  border: none; /* This ensures there is no border applied */
}

.offer-text {
  margin: 2rem;
}

/* Cost Section */
.Cost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Cost h3 {
  font-weight: 700;
}

@media (max-width: 1024px) {
  .CardInfo {
    width: 70%;
  }

  .CardInfo-tail {
    width: 67%;
  }

  .offer-box {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .CardInfo {
    width: 90%;
  }

  .CardInfo-tail {
    width: 86%;
  }

  .offer-box {
    width: 85%;
  }
}

@media (max-width: 500px) {
  .CardInfo {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .CardInfo-tail {
    width: 91%;
    flex-direction: column;
    align-items: center;
  }

  .CheckImg {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .offer-box {
    width: 85%;
    padding: 10px;
  }

  .Offer {
    margin-top: 50px;
  }

  .offer-title {
    top: -20px;
    width: 80%;
  }
}

@media (max-width: 375px) {
  h1.offer-title {
    top: -20px;
    width: 78%;
    font-size: 16px; /* Decrease the font size for h1 */
  }

  .CardInfo-tail {
    width: 88%;
  }
}

@media (max-width: 820px) {
  .offer-title {
    font-size: 1.5em;
    top: -20px;
  }

  .offer-box p {
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .offer-title {
    font-size: 17px;
    top: -20px;
  }

  .offer-box p {
    font-size: 0.9em;
  }
}
