.yearly-container {
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.yearly-inner-container {
  margin-bottom: 15px;
}

.yearly-title {
  color: #27b3df; /* White text for contrast */
  font-size: 46px;
  margin-bottom: 25px;
  font-weight: 600;
}

.card-yearlydetails {
  width: 420px;
  background-color: #f05365; /* Dark pink background color like the image */
  border-radius: 25px;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: #fff; /* White text for readability */
  position: relative;
  z-index: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  animation: fire-shimmer 3s infinite; /* Fire-like shimmering effect */
}

/* Keyframes for the fire-like shimmering border effect */
@keyframes fire-shimmer {
  0% {
    box-shadow: 0 0 10px rgba(255, 85, 0, 0.4), 0 0 20px rgba(255, 85, 0, 0.6);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 165, 0, 0.6), 0 0 40px rgba(255, 165, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 85, 0, 0.4), 0 0 20px rgba(255, 85, 0, 0.6);
  }
}

.yearly-plan-header {
  text-align: left;
}

.yearly-plan-header h2 {
  font-size: 30px;
  font-weight: bold;
  color: #fff; /* White text */
}

.yearly-price {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
}

.yearly-price span {
  font-size: 18px;
  color: #fff; /* White for contrast */
}

.yearly-plan-details {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Bot Build Container: Rounded top-left and top-right corners only */
.yearly-bot-build-container {
  display: flex;
  justify-content: space-between;
  border: 2px solid #fff; /* Fire-like orange border */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid #fff; /* Bottom border for smoother transition */
}

.yearly-total-payment-container {
  display: flex;
  justify-content: space-between;
  border: 2px solid #fff; /* Fire-like orange border */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none; /* No top border for smoother transition */
}

.yearly-bot-build-fee {
  font-size: 18px;
  font-weight: bold;
  color: #fff; /* White for contrast */
  margin: 10px;
}

.yearly-fee-amount {
  font-size: 17px;
  color: #fff; /* White for contrast */
  font-weight: 700;
  margin: 10px;
}

.yearly-total-payment-label {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin: 10px;
}

.yearly-total-amount {
  font-size: 17px;
  color: #fff;
  font-weight: 700;
  margin: 10px;
}

.yearly-footer-plan {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.yearly-footer-text {
  font-size: 15px;
  color: #fff; /* White for contrast */
}

@media (max-width: 1024px) {
  .yearly-plan-details {
    padding: 10px;
  }

  .yearly-bot-build-fee,
  .yearly-total-payment-label {
    font-size: 14px;
  }

  .yearly-fee-amount,
  .yearly-total-amount {
    font-size: 14px;
  }
}
