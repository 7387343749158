.price_table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price-table {
  border-collapse: collapse;
  width: 40%;
  margin-top: 100px;
}

.country-service,
.average-cost {
  width: 50%;
}

.header-row,
.data-row {
  font-weight: 700;
}

.header-row {
  font-size: 28px;
  color: #a04167;
  border-bottom: 1px solid #27b3df;
}

.data-row {
  font-size: 20px;
  border-bottom: 1px solid #27b3df;
}

.price-table-td {
  padding: 30px;
}

.average-cost {
  border-left: 1px solid #27b3df;
}

.logo-cell .logo {
  width: 70%;
  max-width: 100px;
}

.final-cost .h1 {
  font-weight: bold;
  font-size: 43px;
}

.final-row {
  border-right: 1px solid #27b3df;
}

.logo-cell .logo {
  width: 100%;
  max-width: none;
}

.h1 {
  font-family: "CoreCircus", sans-serif;
  font-size: 4rem;
  text-align: center;
  margin: 0;
  color: rgba(45, 45, 45, 1);
  letter-spacing: -0.2rem;
  position: relative;
  animation: flicker 3s linear infinite;
}

.h1:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  color: #ffcc00;
  width: 100%;
  z-index: 5;
  text-shadow: none;
  left: 0;
  top: 0;
  text-align: left;
}

.MO {
  font-weight: bold;
  font-size: 43px;
}

@keyframes flicker {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4),
      1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4),
      1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00,
      0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
    text-shadow: none;
  }
}

@font-face {
  font-family: "CoreCircus2DDot1";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.eot");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.eot?#iefix")
      format("embedded-opentype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.woff2")
      format("woff2"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.woff")
      format("woff"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.ttf")
      format("truetype");
}

@font-face {
  font-family: "CoreCircus";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.eot");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.eot?#iefix")
      format("embedded-opentype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.woff2")
      format("woff2"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.woff")
      format("woff"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.ttf")
      format("truetype");
}

@media (max-width: 1440px) {
  .header-row {
    font-size: 22px;
  }

  .data-row {
    font-size: 16px;
  }

  td {
    font-size: 24px;
    padding: 20px;
  }

  .final-cost .h1 {
    font-size: 30px;
  }

  .MO {
    font-size: 30px;
  }

  .logo-cell .logo {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 1024px) {
  .price-table {
    width: 60%;
  }

  .header-row {
    font-size: 22px;
  }

  .data-row {
    font-size: 16px;
  }

  td {
    font-size: 18px;
    padding: 20px;
  }

  .final-cost .h1 {
    font-size: 30px;
  }

  .MO {
    font-size: 30px;
  }

  .logo-cell .logo {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 768px) {
  .price-table {
    width: 80%;
    font-size: 16px;
  }

  td {
    padding: 20px;
  }

  .logo-cell .logo {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 1336px) {
  .price-table {
    width: 50%;
    font-size: 18px;
  }

  td {
    padding: 25px;
  }

  .header-row {
    font-size: 24px;
  }

  .data-row {
    font-size: 18px;
  }

  .final-cost .h1 {
    font-size: 38px;
  }

  .MO {
    font-size: 38px;
  }
}

@media (max-width: 1200px) {
  .price-table {
    width: 55%;
    font-size: 17px;
  }

  td {
    padding: 20px;
  }

  .header-row {
    font-size: 22px;
  }

  .data-row {
    font-size: 17px;
  }

  .final-cost .h1 {
    font-size: 36px;
  }

  .MO {
    font-size: 36px;
  }
}

@media (max-width: 992px) {
  .price-table {
    width: 65%;
    font-size: 16px;
  }

  td {
    padding: 15px;
  }

  .header-row {
    font-size: 20px;
  }

  .data-row {
    font-size: 16px;
  }

  .final-cost .h1 {
    font-size: 34px;
  }

  .MO {
    font-size: 34px;
  }
}

@media (max-width: 640px) {
  .price-table {
    width: 75%;
    font-size: 14px;
  }

  td {
    padding: 10px;
  }

  .header-row {
    font-size: 18px;
  }

  .data-row {
    font-size: 14px;
  }

  .final-cost .h1 {
    font-size: 30px;
  }

  .MO {
    font-size: 30px;
  }

  .logo-cell .logo {
    width: 75%;
    max-width: 120px;
  }
}

@media (max-width: 425px) {
  .price-table {
    width: 95%;
    font-size: 12px;
  }

  td {
    padding: 10px;
  }

  .header-row {
    font-size: 12px;
  }

  .data-row {
    font-size: 12px;
  }

  .final-cost .h1 {
    font-size: 22px;
  }

  .MO {
    font-size: 28px;
  }

  .logo-cell .logo {
    width: 70%;
    max-width: 120px;
  }
}

@media (max-width: 280px) {
  .price-table {
    width: 100%;
    font-size: 8px;
  }

  td {
    padding: 3px;
    font-size: 14px;
  }

  .header-row {
    font-size: 8px;
  }

  .data-row {
    font-size: 8px;
  }

  .final-cost .h1 {
    font-size: 10px;
  }

  .MO {
    font-size: 14px;
  }

  .logo-cell .logo {
    width: 100%;
    max-width: 150px;
  }
}
