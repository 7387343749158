.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}

.logo-style {
  width: 46%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1400px;
}

.logo-container,
.menu-container {
  display: flex;
  align-items: center;
}

.router-link {
  margin-right: 25px;
}

.p-hover-color:hover {
  color: #de416c;
  cursor: pointer;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  color: aliceblue;
}

.menu-container {
  display: none;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 100%;
}

.menu-container.open {
  display: flex;
  opacity: 1;
  transform: translateY(0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: slideDown 0.5s ease-in-out forwards;
}

.close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: aliceblue;
  z-index: 1002;
}

.hidden {
  display: none;
}

.header-buts {
  padding: 8px 16px;
  font-size: 20px;
  margin: 10px;
  margin-left: 30px;
  background-color: #f44a67;
  color: #fff;
  border-radius: 8px;
  border: none;
  transition: background-color 0.3s ease-in-out;
  width: 250px;
}

.testi-hover-dropdown {
  position: relative;
  display: inline-block;
}

.testi-flex {
  display: flex;
  align-items: center;
}

.testi-arrow {
  margin-left: 15px;
}

.testi-p-hover-color {
  color: #de416c;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.testi-hover-dropdown ul {
  display: none;
  position: absolute;
  top: 95%;
  left: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.testi-hover-dropdown:hover ul {
  display: block;
}

.testi-link {
  text-decoration: none;
}

.testi-style {
  border-top: 1px solid #de416c;
  padding-top: 5px;
  color: #fff;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  margin-top: -5px;
}

.testi-style:hover {
  color: #de416c;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .logo-style {
    width: 65%;
  }
  .header-buts {
    font-size: 15px;
    padding: 7px 14px;
    width: 210px;
  }
  .router-link {
    margin-right: 14px;
  }
}

@media (max-width: 768px) {
  .menu-container {
    flex-direction: column;
  }

  .router-link {
    margin-bottom: 20px; /* Add space between the menu items */
  }

  .header-buts {
    font-size: 12px; /* Decrease the button text size */
    width: 180px; /* Reduce the button width */
    margin-top: 30px;
  }

  .menu-toggle {
    display: block;
  }

  .logo-style {
    width: 40%;
  }
}

@media (max-width: 616px) {
  .logo-style {
    width: 60%;
  }
  .header-buts {
    font-size: 14px;
    padding: 5px 10px;
    width: 140px;
  }
}

@media (max-width: 484px) {
  .logo-style {
    width: 80%;
  }
  .header-buts {
    font-size: 12px;
    padding: 4px 8px;
    width: 120px;
  }
}

@media (max-width: 375px) {
  .header-content {
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }
  .header-buts {
    font-size: 10px;
    padding: 3px 6px;
    width: 100px;
  }
}

@media (min-width: 769px) {
  .menu-container {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}
